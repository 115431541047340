# Tabbar

tabbar.y = Screen.height - tabbar.height
tabbar.states.add
	show: 
		opacity: 1
		y: tabbar.y
	hide:
		opacity: 1
		y: tabbar.y + tabbar.height
tabbar_icons = [tabbar_icon_spotlight, tabbar_icon_search, tabbar_icon_activity, tabbar_icon_profile]
tabbar_icons[1].opacity = .5
tabbar_icons[2].opacity = .5
tabbar_icons[3].opacity = .5



# Create the Spotlight feed

cards = [card_0, card_1, card_2, card_3, card_4]
page = new PageComponent
	width: Screen.width
	height: Screen.height
	scrollHorizontal: false
	backgroundColor: "transparent"
	
for number in [0...cards.length]
	card = cards[number]
	card.x = 0
	page.addPage(card, "bottom")
page.sendToBack()

card_video_0 = new VideoLayer
	parent: card_0
	video: "images/card_drake_" + 0 + ".mp4"
	backgroundColor: 'transparent'
	width: Screen.width
	height: Screen.height
card_video_0.player.autoplay = true
card_video_0.player.loop = true
card_video_0.sendToBack()
card_0_background.visible = no
card_0_play.on Events.Tap, ->
	player_video.player.play()
	player_video.player.currentTime = 0
	card_video_0.player.pause()
	tabbar.states.switch("hide")
	player.states.switch("show")
	miniplayer.visible = no
	#showComments()

	
card_video_1 = new VideoLayer
	parent: card_1
	video: "images/card_drake_" + 1 + ".mp4"
	backgroundColor: 'transparent'
	width: Screen.width
	height: Screen.height
#card_video_1.player.autoplay = true
card_video_1.player.loop = true
card_video_1.sendToBack()
card_1_background.visible = no

card_video_2 = new VideoLayer
	parent: card_2
	video: "images/card_drake_" + 2 + ".mp4"
	backgroundColor: 'transparent'
	width: Screen.width
	height: Screen.height
#card_video_2.player.autoplay = true
card_video_2.player.loop = true
card_video_2.sendToBack()
card_2_background.visible = no

card_video_3 = new VideoLayer
	parent: card_3
	video: "images/card_drake_" + 3 + ".mp4"
	backgroundColor: 'transparent'
	width: Screen.width
	height: Screen.height
#card_video_3.player.autoplay = true
card_video_3.player.loop = true
card_video_3.sendToBack()
card_3_background.visible = no

card_video_4 = new VideoLayer
	parent: card_4
	video: "images/card_drake_" + 4 + ".mp4"
	backgroundColor: 'transparent'
	width: Screen.width
	height: Screen.height
#card_video_4.player.autoplay = true
card_video_4.player.loop = true
card_video_4.sendToBack()
card_4_background.visible = no

# When the current page changes, print the new one 
oldvideo = no
page.on "change:currentPage", ->
	
	if oldvideo
		oldvideo.player.pause()
	
	if page.currentPage == card_0
		card_video_0.player.play()
		oldvideo = card_video_0
		
	if page.currentPage == card_1
		card_video_1.player.play()
		oldvideo = card_video_1
		
	if page.currentPage == card_2
		card_video_2.player.play()
		oldvideo = card_video_2
		
	if page.currentPage == card_3
		card_video_3.player.play()
		oldvideo = card_video_3
		
	if page.currentPage == card_4
		card_video_4.player.play()
		oldvideo = card_video_4

