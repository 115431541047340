# Comments
player_comments.x = 0
player_comments.y = Screen.height
player_comments.bringToFront()
start_y = -160
end_y = -300
comments = [post_comment_1, post_comment_2, post_comment_3]
playbackComments = ->
	post_comment_1.opacity = 0
	post_comment_1.y = 0
	post_comment_2.opacity = 0
	post_comment_2.y = 0	
	post_comment_3.opacity = 0
	post_comment_3.y = 0
	
	Utils.delay 2, ->
		post_comment_1.animate
			properties:
				y: start_y
				opacity: 1
	Utils.delay 4, ->
		post_comment_1.animate
			properties:
				y: end_y
				opacity: 0
	Utils.delay 4, ->
		post_comment_2.animate
			properties:
				y: start_y
				opacity: 1
	Utils.delay 6, ->
		post_comment_2.animate
			properties:
				y: end_y
				opacity: 0
	Utils.delay 6, ->
		post_comment_3.animate
			properties:
				y: start_y
				opacity: 1
	Utils.delay 8, ->
		post_comment_3.animate
			properties:
				y: end_y
				opacity: 0

player_controls.x = 0
player_comments_list.x = 0
player_likers_list.x = 0