# Miniplayer
miniplayer.parent = player
miniplayer.x = 0
miniplayer.y = -miniplayer.height
miniplayer.visible = false
miniplayer.states.add
	show:
		opacity: 1
	hide:
		opacity: 0
miniplayer.draggable.enabled = true
miniplayer.draggable.vertical = false
miniplayer.draggable.directionLock = true
miniplayer.draggable.directionLockThreshold = {x:10, y:10}
isMiniDragging = no
removeMini = endMiniAnimation = false
miniDirectionFactor = 1
miniplayer.on(Events.SwipeUp, maximizeVideo)
miniplayer.on Events.Tap, ->
	if !isMiniDragging
		maximizeVideo()
	else
		isMiniDragging = no
miniplayer.onDragStart ->
	isMiniDragging = yes
miniplayer.onDragMove ->
	if Math.abs(miniplayer.draggable.velocity.x) > 2
		removeMini = true
		if this.draggable.direction is "left" then miniDirectionFactor = -1
		if this.draggable.direction is "right" then miniDirectionFactor = 1
	miniplayer.opacity = (Screen.width - Math.abs(miniplayer.x)) / Screen.width
miniplayer.onDragEnd ->
	if removeMini is true
		miniplayer.animate 
			properties: {x: Screen.width * miniDirectionFactor, opacity: 0}
		player_video.player.pause()
		player_video.player.currentTime = 0
		removeMini = false
	else
		miniplayer.animate 
			properties: {x: 0, opacity: 1}

	isMiniDragging = false
