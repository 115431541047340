overlay_pager = new PageComponent
	width: Screen.width
	height: Screen.height
	scrollVertical: false
	#parent: player_overlay
player_overlay_top.bringToFront()
overlay_pager.visible = false

	

overlay_pager.addPage(player_controls, "right")
overlay_pager.addPage(player_comments_list, "right")
overlay_pager.addPage(player_likers_list, "right")

overlay_pager.on "change:currentPage", ->
	if overlay_pager.currentPage == player_controls
		player_navbar_tabs.states.switch("hide")
		hideActivity()
	if overlay_pager.currentPage == player_comments_list
		showActivity()
		player_navbar_tabs.states.switch("show")
		player_activity_tab_1.states.switch("active")
		player_activity_tab_2.states.switch("inactive")
	if overlay_pager.currentPage == player_likers_list
		player_activity_tab_1.states.switch("inactive")
		player_activity_tab_2.states.switch("active")

player_navbar_tabs.states.add
	show:
		opacity: 1
	hide:
		opacity: 0
player_navbar_tabs.states.switchInstant('hide')

player_activity_tab_1.states.add
	active: 
		scaleX:1
	inactive:
		scaleX:0
player_activity_tab_2.states.add
	active: 
		scaleX:1
	inactive:
		scaleX:0
player_activity_tab_2.states.switchInstant('inactive')

player_overlay_back_x = player_overlay_back.x
player_overlay_back_x_hide = player_overlay_back_x + 60

showActivity = ->
	#player_overlay_back.x = player_overlay_back_x_hide
	player_overlay_back.animate
		properties:
			opacity:1
			#x:player_overlay_back_x
	player_overlay_collapse.animate
		properties:
			opacity:0
	player_overlay_airplay.animate
		properties:
			opacity:0
	player_overlay_back.bringToFront()

hideActivity = ->
	player_overlay_back.animate
		properties:
			opacity:0
			#x: player_overlay_back_x_hide
	player_overlay_collapse.animate
		properties:
			opacity:1
	player_overlay_airplay.animate
		properties:
			opacity:1
	player_overlay_collapse.bringToFront()
	Utils.delay 2, ->
		#player_overlay.states.switch("hide")

player_overlay_back.visible = true
player_overlay_back.opacity = 0
player_overlay_back.on Events.Click, -> 
	overlay_pager.snapToPage(player_controls)
	
player_chat_btn.on Events.Click, (event, layer) ->
	#print "Clicked", layer.name
	overlay_pager.snapToPage(player_comments_list)
	
# Show/Hide Overlay
#player_controls.on Events.Click, (event, layer) ->
	#print "Clicked", layer.name
	#player_overlay.states.next('show', 'hide')
#player_overlay.states.switchInstant('hide')	
